*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
.main{
  width: 100vw;
  padding-top: 50px;
  background-color: rgb(192, 188, 188);
  display: flex;
  flex-direction:column;
  padding: 5%;
  height: 100vh;
}
